import locale77427e78 from '../../src/lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"ja","iso":"ja","file":"ja.js"},{"code":"ko","iso":"ko","file":"ko.js"},{"code":"zh-hant","iso":"zh-Hant","file":"zh-hant.js"},{"code":"en","iso":"en","file":"en.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/opt/render/project/src/studio-ai/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ja","iso":"ja","file":"ja.js"},{"code":"ko","iso":"ko","file":"ko.js"},{"code":"zh-hant","iso":"zh-Hant","file":"zh-hant.js"},{"code":"en","iso":"en","file":"en.js"}],
  localeCodes: ["ja","ko","zh-hant","en"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ja.js': () => import('../../src/lang/ja.js' /* webpackChunkName: "lang-ja.js" */),
  'ko.js': () => import('../../src/lang/ko.js' /* webpackChunkName: "lang-ko.js" */),
  'zh-hant.js': () => import('../../src/lang/zh-hant.js' /* webpackChunkName: "lang-zh-hant.js" */),
}
